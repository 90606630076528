const toTree = (list = [], tree = null) => {
  if (!tree) tree = list.filter(c => !c.groupId);
  tree = tree.map(parentNode => {
    const isChild = node => node.groupId === parentNode.id;
    parentNode.children = toTree(list, list.filter(isChild));
    return parentNode;
  });
  return tree;
};

const withPath = (list = []) => {
  const getParent = (list, groupId) => {
    const parent = list.filter(parent => parent.id === groupId);
    return parent.length ? parent[0] : null;
  }

  const groupWithPath = list.map(node => {
    let path = `${node.id} - ${node.name}`;
    let parent = getParent(list, node.groupId);

    while(parent) {
      path = `${parent.id} - ${parent.name} > ${path}`;
      parent = getParent(list, parent.groupId);
    }

    return {...list, path}
  });

  groupWithPath.sort((a, b) => {
    if(a.path < b.path) return -1;
    if(a.path > b.path) return 1;
    return 0;
  });

  return groupWithPath;
};

module.exports = {
  toTree, withPath
}