<template>
  <div>
    <v-card>
      <v-card-title>Pagina para testar componentes</v-card-title>
      <v-card-text>
        <v-btn @click="$store.dispatch('loadGrupos')">Load</v-btn>
        <v-btn @click="atoTree">toTree</v-btn>
        <v-btn @click="awithPath">awithPath</v-btn>
        <!-- <div>
          {{ getGroups }}
        </div> -->
        <hr />
        <div>
          <div v-for="item in tree" :key="item.id">
            {{ item.name }}
            <div v-for="ch in item.children" :key="ch.id" class="ml-2">
              {{ ch.name }}
              <div v-for="ch1 in ch.children" :key="ch1.id" class="ml-4">
                {{ ch1.name }}
                <div v-for="ch2 in ch1.children" :key="ch2.id" class="ml-4">
                  {{ ch2.name }}
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
        <hr />
        <div>
          <li v-for="item in path" :key="item.id">
            {{ item.name }} {{ item.path }}
          </li>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { toTree, withPath } from "@/Utils/GroupTreePath";
export default {
  name: "Teste",
  components: {},
  data() {
    return {
      tree: [],
      path: [],
    };
  },
  methods: {
    atoTree() {
      let gp = [...this.getGroups];
      // gp.splice(0, 1);
      let t = toTree(gp);
      console.log(t);
      this.tree = t;
      return t;
    },
    awithPath() {
      let t = withPath(this.getGroups);
      console.log(t);
      this.path = t;
      return t;
    },
  },
  computed: {
    ...mapGetters(["getGroups"]),
  },
  mounted() {
    this.$store.dispatch("loadGrupos");
  },
};
</script>

<style>
</style>